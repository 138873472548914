import axios, {AxiosError} from 'axios';
import {parseCookies} from 'nookies';

import {env} from '@/helpers/utils';

const {isAxiosError} = axios;

function getBaseUrl(): string {
  const api = {
    local: 'http://localhost:5001/',
    dev: 'https://api.dev.folde.eu/',
    prod: 'https://api.folde.eu/',
  };

  if (env.isProd) {
    return api.prod;
  } else if (env.isLocal) {
    return api.local;
  } else {
    return api.dev;
  }
}

function handleError(error: unknown): string {
  let errorMessage = '';
  if (isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = `Error ${error.response.status}`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage = 'Error: No response from the remote server';
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = `Error: ${error.message}`;
    }
  }
  return errorMessage;
}

const instance = axios.create({
  baseURL: getBaseUrl(),
});

instance.interceptors.request.use(
  function (config) {
    const token = parseCookies().token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export {instance as default, handleError, isAxiosError};
export type {AxiosError};
